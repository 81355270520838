import { useCallback, useContext, useEffect } from 'react';
import FunnelContext from '../context/FunnelContext';

const useGrabElement = ({ contentRef }) => {
  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  const mouseMoveHandler = useCallback(
    e => {
      if (contentRef.current.style.cursor === 'grabbing') {
        const pos = extraObjects.mousePosition;

        // How far the mouse has been moved
        // const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        const x = e.pageX - contentRef.current.offsetLeft;
        const walk = (x - pos.startX) * 1.5;

        // Scroll the element
        const topMargin = pos.top - dy;
        const leftMargin = pos.left - walk;

        contentRef.current.scrollTop = topMargin;
        contentRef.current.scrollLeft = leftMargin;
      }
    },
    [extraObjects, contentRef]
  );

  const mouseDownHandler = useCallback(
    e => {
      setExtraObjects(
        Object.assign(extraObjects, {
          mousePosition: {
            left: contentRef.current.scrollLeft || 0,
            top: contentRef.current.scrollTop || 0,
            x: e.clientX,
            y: e.clientY,
            startX: e.pageX - contentRef.current.offsetLeft
          }
        })
      );
      contentRef.current.style.cursor = 'grabbing';
      contentRef.current.style.userSelect = 'none';
      contentRef.current.addEventListener('mousemove', mouseMoveHandler);
    },
    [contentRef, mouseMoveHandler, extraObjects, setExtraObjects]
  );

  const mouseUpHandler = useCallback(
    e => {
      contentRef.current.style.cursor = 'grab';
      contentRef.current.style.removeProperty('user-select');
      contentRef.current.removeEventListener('mousemove', mouseMoveHandler);
    },
    [contentRef, mouseMoveHandler]
  );

  useEffect(() => {
    const ele = contentRef.current;
    ele.addEventListener('mousedown', mouseDownHandler);
    ele.addEventListener('mouseup', mouseUpHandler);
    ele.addEventListener('mouseleave', mouseUpHandler);
    return () => {
      ele.removeEventListener('mousedown', mouseDownHandler);
      ele.removeEventListener('mouseup', mouseUpHandler);
      ele.removeEventListener('mouseleave', mouseUpHandler);
    };
  }, [contentRef, mouseDownHandler, mouseUpHandler]);
};

export default useGrabElement;
