import styled from 'styled-components';

export const BumpOfferWrapper = styled.div`
  & * {
    box-sizing: border-box;
    display: flex;
  }
  & .controls {
    margin-right: 24px;
  }
  & .carousel-content {
    padding-right: 24px;
    gap: 15px;
  }
`;

export const ComponentTitle = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #33498e;
  margin-bottom: 8px;
`;

export const BumpOffer = styled.div`
  display: block;
  ${({ carouselContainerPercentage = 70 }) =>
    `width: ${carouselContainerPercentage}% !important;`}
  position: relative;
  overflow: hidden;
  @media (max-width: 500px) {
    padding-right: 8px;
  }
`;

export const BumpOfferItem = styled.div`
  position: relative;
  background: #f2f4f8;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  ${({ minHeightDesktop = 130 }) => `min-height: ${minHeightDesktop}px;`}
  @media (max-width: 500px) {
    ${({ minHeightMobile = 190 }) => `min-height: ${minHeightMobile}px;`}
  }
`;

export const BumpOfferImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 72px;
  justify-content: center;
  align-items: end;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  & picture {
    & img {
      border-radius: 0 0 0 8px;
      width: 100%;
    }
  }
`;

export const SaleBadge = styled.div`
  max-width: 38px;
  background: #bc2e3e;
  border-radius: 4px;
  padding: 2px 8px 0;
  font-family: 'Utopia Std', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding: 16px 16px 16px 8px;
  position: relative;

  & > input:checked + div:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-color: #001c72;
    border-radius: 4px;
  }

  & > input:checked + div:after {
    content: '';
    display: block;
    width: 8px;
    height: 18px;
    background-color: #001c72;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    transform: rotate(37deg);
    top: 0;
    left: 6px;
  }
`;

export const SaleBadgeAbsolute = styled(SaleBadge)`
  position: absolute;
  right: 0;
`;

export const CheckboxInput = styled.input`
  display: none;
`;

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  right: 16px;
  top: 16px;
  border: 2px solid #001c72;
  cursor: pointer;
  z-index: 200;
  @media (max-width: 375px) {
    bottom: ${({ mobileCards }) => (mobileCards === 'slim' ? '0' : 'auto')};
  }
  &.active {
    background: #001c72;
  }
  &.active::before {
    position: absolute;
    content: '';
    top: -1px;
    left: -1px;
    width: 8px;
    height: 20px;
    border-right: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(255, 255, 255);
    z-index: 1;
    transform: rotate(37deg);
    transform-origin: 100% 100%;
  }

  &.active::after {
    position: absolute;
    content: '';
    left: 0px;
    z-index: 0;
    transition: border 0.25s ease 0s, background-color 0.25s ease 0s,
      width 0.2s ease 0.1s, height 0.2s ease 0.1s, top 0.2s ease 0.1s,
      left 0.2s ease 0.1s;
  }
`;

export const DiscountPercentage = styled.div`
  margin: 0 0 auto;
  display: flex;
  background: linear-gradient(238.35deg, #cd0053 19.5%, #001c72 120.35%);
  padding: 2px;
  border-radius: 20px;
  @media (min-width: 768px) and (max-width: 998px) {
    width: 100%;
    margin-top: 8px;
  }
  & > div {
    font-family: 'Neuzeit Grotesk', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    background: #f2f4f8;
    padding: 5px 20px 2px;
    border-radius: 20px;
    @media (min-width: 768px) and (max-width: 998px) {
      width: 100%;
    }
    & > span {
      background: linear-gradient(238.35deg, #cd0053 19.5%, #001c72 120.35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      padding: 5px 10px 2px;
    }
  }
`;

export const DisplayTitle = styled.div`
  padding-right: 30px;
  display: flex;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
  color: #001c72;
  position: relative;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 30px;
`;

export const DisplayDesc = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const SaleBadgeMobile = styled(SaleBadge)`
  position: static;
  display: none;

  @media (max-width: 642px) {
    display: ${({ mobileCards }) => (mobileCards === 'slim' ? 'flex' : 'none')};
    margin: 3px auto 2px;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: auto;
  width: 100%;
  @media (min-width: 768px) and (max-width: 998px) {
    flex-direction: column;
  }
`;

export const Price = styled.div`
  margin: auto auto auto 0;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  display: flex;
  gap: 8px;
`;

export const DiscountedPrice = styled.span`
  font-weight: 700;
  color: #001c72;
`;

export const FullPrice = styled.span`
  font-weight: 400;
  text-decoration-line: line-through;
  color: #33498e;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
