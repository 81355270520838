import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './BumpOfferScrollPDP2023v2.styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import BumpCarouselV2 from '../BumpCarousel/BumpCarouselV2';
import BumpOfferScrollPDP2023v2Item from './BumpOfferScrollPDP2023v2Item';

const BumpOfferScroll = props => {
  const {
    showTitle,
    otpTitle,
    subTitle,
    width,
    mobileWidth,
    carouselContainerPercentage = 70
  } = props;

  const { onetime, subscription, currentCategory } = useContext(FunnelContext);

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  return (
    <S.BumpOfferWrapper>
      <BumpCarouselV2
        slidesVisible={2}
        showControls={false}
        width={width}
        mobileWidth={mobileWidth}
        shadow={''}
        showTitle={showTitle}
        otpTitle={otpTitle}
        subTitle={subTitle}
        carouselContainerPercentage={carouselContainerPercentage}
      >
        {bumpoffers.map((item, index) => {
          return (
            <BumpOfferScrollPDP2023v2Item
              item={item}
              index={index}
              key={index}
              {...props}
            />
          );
        })}
      </BumpCarouselV2>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferScroll;
